import React from 'react';

import { Outlet, Route, Routes, useLocation } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useTransition, animated } from 'react-spring';

import { About } from './components/about';
import { Container } from './components/container';
import { Portfolio } from './components/portfolio';


export function RouterContainer(props:any)
{
  return (
    <div className="content">
      {props.children}
    </div>
  );
}
// export function RouteContainer(props:any)
// {
//   return (
//     <animated.div className="container fullscreen">
//       {props.children}
//     </animated.div>
//   );
// }

// export function RouteContainer()
// {
//   return (
//     <Container className="fullscreen">
//       <Outlet />
//     </Container>
//   );
// }

export function Router()
{
  // const container = RouteContainer();
  const location = useLocation();
  // const { location } = useRouter();

  // const [items, setItems] = useState(NUM_TRANS)
  // const transitions = useTransition(items, {
  // from: { opacity: 0 },
  // enter: { opacity: 1 },
  // leave: { opacity: 0 },
  // delay: 200,
  // config: config.molasses,
  // onRest: () => setItems([]),
  // })

  // useEffect(() => {
  // if (items.length === 0) {
  // setTimeout(() => {
  // setItems(NUM_TRANS)
  // }, 2000)
  // }
  // }, [items])

// const transitions = useTransition(location, {
// from: { opacity: 0, transform: 'translate3d(100vw, 0, 0)' },
// enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
// leave: { opacity: 0, transform: 'translate3d(-100vw, 0, 0)' },
// });

const transitions = useTransition(location, {
from: { opacity: 0, },
enter: { opacity: 1 },
leave: { opacity: 0 },
});

  // const transitions = useTransition(location, {
  //   from: { opacity: 0, },
  //   enter: { opacity: 1, },
  //   leave: { opacity: 0, },
  //   });

  return (
    <RouterContainer>
    {
      transitions((props, item) =>
        <animated.div className="animated wrapper" style={props}>
          <Routes location={item}>
            <Route path="/">
              <Route index element={<About/>} />
              <Route path="/portfolio" element={<Portfolio/>} />
            </Route>
          </Routes>
        </animated.div>
      )
    }
    </RouterContainer>
  );

  // return (
  //   <TransitionGroup>
  //     <CSSTransition
  //       key={loc.key}
  //       classNames="animation-slide"
  //       timeout={1000}>

  //       <Routes location={loc}>
  //         <Route path="/" element={container}>
  //           <Route index element={<About/>} />
  //           <Route path="/portfolio" element={<Portfolio/>} />
  //         </Route>
  //       </Routes>

  //     </CSSTransition>
  //   </TransitionGroup>
  // );
}
