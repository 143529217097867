import "./object.d"

// export interface IObjectExtensions
// {
//   assignDefaults(defaults:any):void;
// }

// // export class ObjectExtensions<K, V> extends Map<K, V> implements IObjectExtensions
// export class ObjectExtensions extends Object implements IObjectExtensions
// {
//   constructor(defaults={}, ...args:any[])
//   {
//     super(...args);

//     this.assignDefaults(defaults);
//   }

//   // assignDefaults<K, V>(defaults:Map<K, V>):void
//   assignDefaults(defaults:Object):void
//   {
//     // defaults.forEach((v:V, k:K) =>

//     for(const k in defaults)
//     {
//       const that = <any>this;
//       that[k] = that[k] || defaults[k];
//       // console.log(k, v);
//       // const that = this as unknown;
//       // const that2 = <Map<K,V>> that;


//       // const value:any = that2.get(k);

//       // if(!value)
//       // {
//       //   that2.set(k, v);
//       // }

//       // <K,V>this[key] = this[key] || value;
//     // });
//     }
//   }
// }

// export function assign<T>(target:T, defaults:T, type: { new(): T ;}):T
// export function pluck<T>(source:any, defaults:T):any & T

// module Objectt
// {
//   export function union<T>(target:T, source:any):any & T
//   {
//     let ret:any & T = {...target};

//     for(const k in source)
//     {
//       // if(target[k])
//       // {
//       //   continue;
//       // }
      
//       if(!ret[k])
//       {
//         ret[k] = source[k];
//       }

//       // ret[k] = ret[k] ? source[k] : defaults[k];

//     //   // (target as any)[k] = (defaults as any)[k];

//     //   // const fragment:T = new type();
//     //   // fragment[k] = defaults[k];

//     //   // (target as any)[k] = defaults[k];
//     //   // target[k] = fragment[k];

//     //   // Object.assign(target, fragment);


//     //   // Object.assign(target, <T>{[k]: defaults[k]});
//     //   // target[k] = defaults[k];
//     }

//     return ret;
//   }
// }

/*
export function intersect<T>(context: T, other:any):any & T
{
  let ret:any & T = {...context};

  // for(const k in other)
  // {
  //   if(ret[k])
  //   {
  //     continue;
  //   }
    
  //   ret[k] = other[k];
  // }
  //

  ret.intersection(other);

  return ret;
}

export function intersection<T>(context: T, other:T):T
{
  for(const k in other)
  {
    if(k in context)
    {
      continue;
    }
    
    context[k] = other[k];
  }

  return context;
}
*/

// Object.prototype.intersect = function<T>(this: T, other:any):any & T
// {
//   // return intersect(this, other);
//   return ObjectExtensions.intersect(this, other);
// }

// Object.prototype.intersection = function<T>(this: T, other:T):T
// {
//   // return intersection(this, other);
//   return ObjectExtensions.intersection(this, other);
// }

export interface IObjectExtensions
{
  intersect<T>(context:T, other:any):T
  intersection<T>(context:T, other:T):T
}

export module ObjectExtensions
{
  export function intersect<T>(context:T, other:any):T
  {
    // return intersect(context, other);
    
    let ret:any & T = {...context};

    // for(const k in other)
    // {
    //   if(ret[k])
    //   {
    //     continue;
    //   }
      
    //   ret[k] = other[k];
    // }
    //

    ObjectExtensions.intersection(ret, other);

    return ret;
  }

  export function intersection<T>(context:T, other:T):T
  {
    // return intersection(context, other);
    
    for(const k in other)
    {
      if(k in context)
      {
        continue;
      }
      
      context[k] = other[k];
    }

    return context;
  }
}

export default ObjectExtensions;
