import React from 'react';

import
{
  IComponent,
  IComponentOptions
}
from './interface';

import '../extensions/string';

export interface IAttributionOptions extends IComponentOptions
{
  description?:string;
  owner?:string;
}

export default function Attribution(props:IAttributionOptions)
{
  const
  {
    description,
    owner,
  } = props;
  
  return (
    <div className="attribution">
      {
        owner &&
          <p>Photograph by:<br/>{owner.toJSXString()}</p>
      }

      {
        description &&
          <p>{description.toJSXString()}</p>
      }
    </div>
  );
}
