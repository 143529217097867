import React from 'react';


// type StringWithoutToString = new(entries?: String | null) =>
// {
//   [P in Exclude<keyof String, 'toString'>] : String[P]
// }

// const StringWithoutToString: StringWithoutToString = String;

import Key from '../utils/key';

import './string.d';

String.prototype.toJSXString = function(this: string):(string | JSX.Element)[][]
{
  return StringExtensions.toJSXString(this);
}

export interface IStringExtensions
{
  toJSXString():(string | JSX.Element)[][];
}

// export function toJSXString(source:string):(string | JSX.Element)[][]
// {
//   return source.split('\n').map((str) => [str, <br key={Key.gen()}/>]);
// }

export module StringExtensions
{
  export function toJSXString(source:string):(string | JSX.Element)[][]
  {
    return source.split('\n').map((str) => [str, <br key={Key.gen()}/>]);
  }
}

// export class StringExtensions
//   extends String
//   implements IStringExtensions
// {
//   static toJSXString(that:string):(string | JSX.Element)[][]
//   {
//     return that.split('\n').map((str) => [str, <br key={Key.gen()}/>]);
//   }

//   toJSXString():(string | JSX.Element)[][]
//   {
//     return StringExtensions.toJSXString(this as string);
//   }
// }

export default StringExtensions;
